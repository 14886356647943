.block05 {
  padding: _calc(60) _calc(20);
  background-color: $block05_bg;

  @media #{$small_over} {
    display: flex;
    align-items: center;
    padding: _calc(80) _calc(120);

    &.re {
      flex-direction: row-reverse;
    }
  }

  &-block {
    @media #{$small_over} {
      width: 50%;
      padding: 0 _calc(80);
    }
  }

  &-head {
    font-size: .9rem;
    font-weight: 500;
    padding: 0 0 _calc(20);

    @media #{$small_over} {
      font-size: 1.3rem;
      padding: 0 0 _calc(30);
    }
  }

  &-title {
    font-size: 1.3rem;
    font-weight: 600;

    @media #{$small_over} {
      font-size: 1.8rem;
    }
  }

  &-catch {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;

    @media #{$small_over} {
      font-size: 2.3rem;
    }

    &-sub {
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      padding: _calc(15) 0 0;

      @media #{$small_over} {
        font-size: 1.1rem;
      }
    }
  }

  &-text {
    font-size: .9rem;
    padding: _calc(30) 0;

    @media #{$small_over} {
      font-size: 1rem;
      line-height: 2;
      padding: _calc(40) 0 0;
    }
  }

  &-btn {
    font-size: 1rem;
    padding: _calc(10) 0 _calc(50);

    @media #{$small_over} {
      padding: _calc(60) 0 0;
    }

    a {
      display: inline-block;
      border: solid #000 1px;
      padding: _calc(10) _calc(30);
      background-color: $block05_btn_bg;
      color: $block05_btn_color;

      @media #{$small_over} {
        padding: _calc(10) _calc(40);
      }
    }
  }

  figure {
    @media #{$small_over} {
      width: 50%;
    }
  }
}