.block18 {
  &-list {
    @media #{$small_over} {
      width: _calc(1000);
      margin: 0 auto;
    }

    &__item {
      padding: 0 0 _calc(50);

      @media #{$small_over} {
        padding: 0 0 _calc(80);
      }
    }

    &__title {
      font-size: 1.2rem;
      font-weight: 500;
      padding: _calc(50) _calc(20) 0;

      @media #{$small_over} {
        font-size: 1.6rem;
        text-align: center;
        padding: _calc(80) 0 0;
      }
    }

    &__text {
      font-size: 1rem;
      font-weight: 500;
      padding: _calc(20) _calc(20) 0;

      @media #{$small_over} {
        font-size: 1.1rem;
        text-align: center;
        padding: _calc(30) 0 0;
      }
    }
  }
}