.block09 {
  background-color: $block09_bg;

  &-list {
    border-top: solid $block09_border 1px;

    &__item {
      border-bottom: solid $block09_border 1px;

      @media #{$small_over} {
        display: flex;
        align-items: center;

        &:nth-child(odd) {
          flex-direction: row-reverse;
        }
      }
    }

    &__block {
      padding: _calc(60) _calc(30);

      @media #{$small_over} {
        width: 50%;
        padding: _calc(20) _calc(80);
      }
    }

    &__title {
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;

      @media #{$small_over} {
        font-size: 2.2rem;
      }
    }

    &__subtitle {
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      padding: _calc(20) 0 0;

      @media #{$small_over} {
        font-size: 1.5rem;
      }
    }

    &__text {
      font-size: .9rem;
      padding: _calc(20) 0 0;

      @media #{$small_over} {
        font-size: 1rem;
      }
    }

    figure {
      @media #{$small_over} {
        width: 50%;
      }

      img {
        width: 100%;
      }
    }
  }
}