@import "__config";
@import "__nav";

header {
  position: relative;
  height: _calc($header_height_sp);
  background-color: $header_bg;
  display: flex;
  align-items: center;
  padding: 0 0 0 _calc(25);

  @media #{$small_over} {
    justify-content: center;
    padding: 0;
  }

  h1 {
    font-size: 1.2rem;
    color: $header_text;
    font-family: $h1_font_family;
    font-weight: $h1_font_weight;

    @media #{$small_over} {
      font-size: 2.1rem;
      padding: _calc(30) 0;
    }
  }

  figure {
    img {
      width: 100%;
    }
  }
}

@import "__title01";

@import "__block04";
@import "__block05";
@import "__block09";
@import "__block13";
@import "__block16";
@import "__block17";
@import "__block18";
@import "__block19";
@import "__block20";
@import "__block21";

@import "__contact01";

@import "__footer";