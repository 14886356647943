.block19 {
  padding: _calc(60) 0 0;

  @media #{$small_over} {
    padding: _calc(100) 0 0;
  }

  &-title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    padding: 0 _calc(20);

    @media #{$small_over} {
      font-size: 2.4rem;
    }
  }

  &-list {
    padding: _calc(20) _calc(20) 0;

    @media #{$small_over} {
      padding: _calc(50) _calc(200) 0;
    }

    &__item {
      padding: _calc(30) 0 0;

      @media #{$small_over} {
        display: flex;
        align-items: flex-start;
      }
    }

    &__title {
      width: _calc(120);
      border-radius: 20px;
      background-color: $block19_list_bg;
      color: $block19_list_color;
      text-align: center;
      font-size: .9rem;
      line-height: 2.4;

      @media #{$small_over} {
        width: _calc(140);
        font-size: 1rem;
      }
    }

    &__text {
      color: $block19_list_text;
      font-size: 1rem;
      line-height: 1.8;
      padding: _calc(15) _calc(10) 0;

      @media #{$small_over} {
        padding: 0 0 0 _calc(50);
      }

      span {
        font-size: .9rem;
      }
    }
  }

  &-map {
    padding: _calc(50) 0 0;

    @media #{$small_over} {
      width: 100%;
      padding: _calc(100) 0 0;
    }

    iframe {
      width: 100%;
    }
  }


}