.footer {
  padding: _calc(20) 0;
  background-color: $footer_bg;
  color: $footer_text;

  @media #{$small_over} {
    padding: _calc(40) 0;
  }

  &-title {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: center;
    padding: 0 0 _calc(15);

    @media #{$small_over} {
      font-size: 1.4rem;
      padding: 0 0 _calc(20);
    }
  }

  &-text {
    font-size: .9rem;
    line-height: 1.6;
    text-align: center;
    padding: 0 0 _calc(15);

    @media #{$small_over} {
      font-size: 1rem;
      padding: 0 0 _calc(20);
    }
  }

  .copy {
    text-align: center;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1.1rem;
      display: inline-block;
      padding: 0 _calc(5) 0 0;
    }
  }
}