.block16 {
  background-color: $block16_bg;
  padding: _calc(60) 0 0;

  @media #{$small_over} {
    padding: _calc(100) _calc(120) _calc(60);
  }

  &-title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    padding: 0 _calc(20);

    @media #{$small_over} {
      font-size: 2.4rem;
    }
  }

  &-block {
    padding: _calc(10) _calc(20) _calc(30);

    @media #{$small_over} {
      padding: _calc(20) _calc(30) _calc(40);
    }
  }

  &-text {
    font-size: .9rem;
    padding: _calc(20) 0 0;

    @media #{$small_over} {
      font-size: 1rem;
      padding: _calc(40) 0 0;
    }
  }

  &-image {
    position: relative;

    &__block {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: #000, $alpha: .5);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 _calc(30);
    }

    &__text {
      font-size: 1rem;
      text-align: center;

      @media #{$small_over} {
        font-size: 1.2rem;
      }
    }
  }

  figure {
    padding: _calc(20) 0 0;

    @media #{$small_over} {
      padding: _calc(40) 0 0;
    }

    img {
      width: 100%;
    }
  }
}