nav {
  .navi {
    position: fixed;
    top: _calc(25);
    right: _calc(25);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 3;
    border-radius: 50%;
    background-color: $navi_bg;

    @media #{$small_over} {
      display: none;
    }

    span {
      position: relative;
      display: block;
      background-color: #000;
      width: 12px;
      height: 1px;

      @media #{$small_over} {
        width: 13px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 12px;
        height: 1px;
        background-color: #000;
        position: absolute;
        transition: all 0.3s ease;

        @media #{$small_over} {
          width: 13px;
        }
      }

      &::before {
        top: -4px;

        @media #{$small_over} {
          top: -5px;
        }
      }

      &::after {
        top: 4px;

        @media #{$small_over} {
          top: 5px;
        }
      }
    }

    &.on {
      span {
        background-color: transparent;

        &::before,
        &::after {
          width: 14px;
          top: 0;
          left: 50%;

          @media #{$small_over} {
            width: 20px;
          }
        }

        &::before {
          transform: translateX(-50%) rotate(45deg);
        }

        &::after {
          transform: translateX(-50%) rotate(-45deg);
        }
      }
    }
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: #fff;
    padding: _calc(120) _calc(30) 0;
    transform: translate(0, 0);
    transition: all 0.3s ease-out;
    display: none;
    z-index: 2;
    background-color: $menu_bg;

    &.show {
      display: block;
    }

    @media #{$small_over} {
      position: static;
      height: 100%;
      display: flex;
      justify-content: center;
      overflow: visible;
      padding: _calc(30);
    }

    &-list {
      @media #{$small_over} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &__item {
        font-size: 1rem;
        line-height: 1;
        padding: 0 0 _calc(70);

        @media #{$small_over} {
          padding: 0 _calc(35);
        }

        a {
          display: block;
        }

        &::before {
          display: none;
        }
      }
    }
  }
}