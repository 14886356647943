.block17 {
  padding: _calc(80) _calc(30) _calc(30);

  @media #{$small_over} {
    padding: _calc(120) _calc(80) _calc(50);
  }

  &-title {
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;

    @media #{$small_over} {
      font-size: 2.1rem;
    }
  }

  &-list {
    padding: _calc(40) 0 0;

    @media #{$small_over} {
      display: flex;
      justify-content: space-between;
      padding: _calc(60) 0 0;
    }

    &__item {
      border: solid $block17_border 1px;
      margin: 0 0 _calc(30);

      @media #{$small_over} {
        width: 31%;
      }
    }

    &__block {
      padding: _calc(20);
    }

    &__title {
      font-size: 1.3rem;

      @media #{$small_over} {}
    }

    &__text {
      font-size: 1rem;
      line-height: 1.8;
      border-top: solid $block17_border 1px;
      margin: _calc(20) 0 0;
      padding: _calc(20) 0 0;
    }
  }
}