.block20 {
  @media #{$small_over} {}

  &-list {
    &__item {
      padding: _calc(60) 0;

      &:nth-child(even) {
        background-color: $block20_list_bg;
      }
    }

    figure {
      width: 70%;
      margin: 0 auto _calc(10);

      @media #{$small_over} {
        width: _calc(300);
      }

      img {
        border-radius: 50%;
        width: 100%;
      }
    }

    &__title {
      font-size: 1.1rem;
      line-height: 2.4;
      text-align: center;

      @media #{$small_over} {
        font-size: 1.2rem;
      }
    }

    &__text {
      font-size: 1rem;
      line-height: 1.8;
      padding: _calc(15) _calc(20) 0;

      @media #{$small_over} {
        width: _calc(600);
        margin: 0 auto;
      }

      span {
        font-size: .9rem;
      }
    }
  }
}