.contact01 {
  background-color: $contact_bg;
  padding: _calc(50) 0 0;

  @media #{$small_over} {
    display: flex;
    flex-wrap: wrap;
  }

  &-title {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0 _calc(20);

    @media #{$small_over} {
      padding: _calc(50) 0 0 _calc(200);
      width: 40%;
    }
  }

  &-list {
    @media #{$small_over} {
      width: 60%;
    }

    &__item {
      padding: _calc(30) _calc(20) 0;

      @media #{$small_over} {
        padding: _calc(50) 0 0 0;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      font-size: 1rem;

      @media #{$small_over} {
        font-size: 1.1rem;
      }

      &::before {
        content: "";
        display: block;
        width: _calc(30);
        height: _calc(30);
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin: 0 _calc(5) 0 0;

        @media #{$small_over} {
          margin: 0 _calc(7) 0 0;
        }
      }

      &.address {
        &::before {
          background-image: url(../common/icon/map.svg);
        }
      }

      &.tel {
        &::before {
          background-image: url(../common/icon/call.svg);
        }
      }

      &.mail {
        &::before {
          background-image: url(../common/icon/mail.svg);
        }
      }
    }

    &.wh &__title {
      &.address {
        &::before {
          background-image: url(../common/icon/map_wh.svg);
        }
      }

      &.tel {
        &::before {
          background-image: url(../common/icon/call_wh.svg);
        }
      }

      &.mail {
        &::before {
          background-image: url(../common/icon/mail_wh.svg);
        }
      }
    }

    &__text {
      font-size: 1.1rem;
      padding: _calc(10) 0 0;

      @media #{$small_over} {
        font-size: 1.2rem;
        padding: _calc(15) 0 0;
      }
    }
  }

  &-map {
    padding: _calc(50) 0 0;

    @media #{$small_over} {
      width: 100%;
      padding: _calc(100) 0 0;
    }

    iframe {
      width: 100%;
    }
  }
}