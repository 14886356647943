.block04 {
  position: relative;
  text-align: center;

  &-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: $block04_bg, $alpha: $block04_alpha);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
  }

  &-title {
    font-size: 1.4rem;
    font-weight: 500;

    @media #{$small_over} {
      font-size: 2.4rem;
    }
  }

  &-text {
    font-size: .9rem;
    padding: _calc(10) 0 0;

    @media #{$small_over} {
      font-size: 1rem;
      padding: _calc(20) 0 0;
    }
  }

  &-btn {
    font-size: 1rem;
    padding: _calc(25) 0 0;

    @media #{$small_over} {
      padding: _calc(40) 0 0;
    }

    a {
      display: inline-block;
      border: solid #fff 1px;
      padding: _calc(10) 0;
      width: _calc(200);

      @media #{$small_over} {
        padding: _calc(10) _calc(80);
        width: auto;
      }
    }
  }

  figure {
    img {
      width: 100%;
    }
  }
}