$small_over: "only screen and ( min-width:481px )";

@function _calc($n) {
  @return calc(#{$n} / var(--lo1) * var(--lo2));
}

:root {
  --lo1: 390;
  --lo2: 100vw;
}

@media #{$small_over} {
  :root {
    --lo1: 1440;
    --lo2: 100vw;
  }
}

$font_family: "Zen Kaku Gothic New", sans-serif;
$body_bg: #fff;
$body_text: #000;

$header_height_sp: 90;
$header_height_pc: 140;
$header_bg: #fff;
$header_text: #000;

$h1_font_family: serif;
$h1_font_weight: 500;

$navi_bg: #fff;
$navi_line: #fff;
$menu_bg: #f6f6f6;

$block04_bg: #000;
$block04_alpha: .4;

$block05_bg: #fff;
$block05_btn_bg: #000;
$block05_btn_color: #fff;

$block09_bg: #fff;
$block09_border: transparent;

$block14_bg: #000;

$block16_bg: #f6f6f6;

$block17_border: #666;

$block18_bg: #000;
$block18_alpha: .4;

$block19_list_bg: #757575;
$block19_list_color: #fff;
$block19_list_text: #000;

$block20_list_bg: #f6f6f6;

$contact_bg: #fff;

$footer_bg: #fff;
$footer_text: #000;