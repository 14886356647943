.block13 {
  @media #{$small_over} {
    display: flex;
    align-items: center;
  }

  &-block {
    padding: _calc(50) _calc(20);

    @media #{$small_over} {
      width: 50%;
      padding: 0 _calc(50);
    }
  }

  &-title {
    font-size: 2rem;
    line-height: 1.6;

    @media #{$small_over} {
      font-size: 3.2rem;
      line-height: 1.4;
    }

    &-en {
      font-size: 1.1rem;
      line-height: 1.6;
      padding: _calc(20) 0 0;

      @media #{$small_over} {
        font-size: 1.8rem;
        line-height: 1.4;
      }
    }
  }

  &-text {
    font-size: 1rem;
    line-height: 1.6;
    padding: _calc(20) 0 0;

    @media #{$small_over} {
      font-size: 1.1rem;
      line-height: 1.8;
      padding: _calc(40) 0 0;
    }
  }

  &-btn {
    font-size: 1rem;
    text-align: center;
    padding: _calc(40) 0 0;

    @media #{$small_over} {
      padding: _calc(60) 0 0;
    }

    a {
      display: inline-block;
      border: solid #000 1px;
      padding: _calc(10) 0;
      width: _calc(200);

      @media #{$small_over} {
        padding: _calc(10) _calc(40);
        width: auto;
      }
    }
  }

  figure {
    @media #{$small_over} {
      width: 50%;
    }

    img {
      width: 100%;
    }
  }
}