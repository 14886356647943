.title01 {
  text-align: center;
  padding: _calc(50) _calc(20);

  @media #{$small_over} {
    padding: _calc(80) 0;
  }

  &-title {
    font-size: 1.4rem;

    @media #{$small_over} {
      font-size: 2rem;
    }
  }

  &-text {
    font-size: .9rem;
    padding: _calc(10) 0 0;

    @media #{$small_over} {
      font-size: 1.1rem;
      padding: _calc(20) 0 0;
    }
  }
}